const hostname = window && window.location && window.location.hostname;

// let backendHost:string = "https://ndwapi.newgendigital.com/pascal";
 let backendHost: String = "https://dev.maths.engineering/pascal";
//let backendHost: String = "https://www.maths.engineering/pascal";


// if (hostname === 'localhost') {
//     backendHost = 'http://localhost:9090';
// }

export const apiHost = backendHost + '/graphql';